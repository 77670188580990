<template>
  <div>
    <Filters
      :copy="copy"
      :rows="rows"
      @changed="handleChangeRows"
      :datum="datum"
      :anbieter="anbieter"
      :effectivzins="effectivzins"
      :zinssatz="zinssatz"
      :rendite="rendite"
    />
    <div>
      <v-data-table
        :headers="headers"
        :items="copy"
        :items-per-page="copy.length"
        class="elevation-1 nibc-table"
        item-key="name"
        fixed-header
        hide-default-footer
      >
        <template v-slot:[`item.regionaleBank`]="{ item }">
          <span v-if="item.regionaleBank === 'Ja'">{{
            item.regionaleBank
          }}</span>
        </template>
        <template v-slot:[`item.effektivzins`]="{ item }">
          <span>{{ `${separator(item.effektivzins)} %` }}</span>
        </template>
        <template v-slot:[`item.datum`]="{ item }">
          <span>{{ $date(item.datum).format('DD.MM.YYYY') }}</span>
        </template>
        <template v-slot:[`item.sollzinsbindung`]="{ item }">
          <span>{{ `${item.sollzinsbindung} Jahre` }}</span>
        </template>
        <template v-slot:[`item.darlehen`]="{ item }">
          <span>{{ `${separator(item.darlehen)} €` }}</span>
        </template>
        <template v-slot:[`item.kaufpreis`]="{ item }">
          <span>{{ `${separator(item.kaufpreis)} €` }}</span>
        </template>
        <template v-slot:[`item.zinssatz`]="{ item }">
          <span>{{ `${separator(item.zinssatz)} %` }}</span>
        </template>
        <template v-slot:[`item.rendite`]="{ item }">
          <span>{{ `${separator(item.rendite)} %` }}</span>
        </template>
        <template v-slot:[`item.neukundenangebot`]="{ item }">
          <span v-if="item.neukundenangebot === true">Ja</span>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import Filters from '../Filters/Filters';

import { modifyNumber } from '../../../../../src/utils/helpers';

export default {
  name: 'SpezialabonnentenTable',
  props: {
    headers: Array,
    rows: Array,
    anbieter: {
      type: Boolean,
      default: false,
    },
    effectivzins: {
      type: Boolean,
      default: false,
    },
    datum: {
      type: Boolean,
      default: false,
    },
    zinssatz: {
      type: Boolean,
      default: false,
    },
    rendite: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Filters,
  },
  data() {
    return {
      copy: [],
      min: '',
      max: '',
    };
  },
  watch: {
    rows(val) {
      this.copy = [...val];
    },
  },
  mounted() {
    this.copy = [...this.rows];
  },
  methods: {
    separator(val) {
      return modifyNumber(val);
    },
    handleChangeRows(val) {
      this.copy = val;
    },
  },
};
</script>
<style lang="scss" scoped></style>
