var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Filters',{attrs:{"copy":_vm.copy,"rows":_vm.rows,"datum":_vm.datum,"anbieter":_vm.anbieter,"effectivzins":_vm.effectivzins,"zinssatz":_vm.zinssatz,"rendite":_vm.rendite},on:{"changed":_vm.handleChangeRows}}),_c('div',[_c('v-data-table',{staticClass:"elevation-1 nibc-table",attrs:{"headers":_vm.headers,"items":_vm.copy,"items-per-page":_vm.copy.length,"item-key":"name","fixed-header":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.regionaleBank",fn:function(ref){
var item = ref.item;
return [(item.regionaleBank === 'Ja')?_c('span',[_vm._v(_vm._s(item.regionaleBank))]):_vm._e()]}},{key:"item.effektivzins",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(((_vm.separator(item.effektivzins)) + " %")))])]}},{key:"item.datum",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$date(item.datum).format('DD.MM.YYYY')))])]}},{key:"item.sollzinsbindung",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(((item.sollzinsbindung) + " Jahre")))])]}},{key:"item.darlehen",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(((_vm.separator(item.darlehen)) + " €")))])]}},{key:"item.kaufpreis",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(((_vm.separator(item.kaufpreis)) + " €")))])]}},{key:"item.zinssatz",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(((_vm.separator(item.zinssatz)) + " %")))])]}},{key:"item.rendite",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(((_vm.separator(item.rendite)) + " %")))])]}},{key:"item.neukundenangebot",fn:function(ref){
var item = ref.item;
return [(item.neukundenangebot === true)?_c('span',[_vm._v("Ja")]):_vm._e()]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }