<template>
  <v-row class="download-file">
    <v-col cols="4">
      <v-row class="justify-start">
        <v-card-actions class="pl-0 pr-0 flex-wrap tooltip-container">
          <v-tooltip
            top
            :color="baseColor"
            absolute
            :attach="true"
            class="tooltip"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :loading="loading"
                :disabled="loading"
                :color="color"
                v-bind="attrs"
                v-on="on"
                @click="getCSV"
              >
                <v-icon color="#fff">mdi-file-excel</v-icon>
              </v-btn>
            </template>
            <span class="text-tooltip inter hint-color"
              >CSV/Excel-Export:<br />(Gesamttabelle)</span
            >
          </v-tooltip>
        </v-card-actions>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import {
  BASE_BLACK_COLOR,
  BASE_COLOR,
  EXTENSIONS,
} from '../../../../../src/utils/constants';
import { mapActions } from 'vuex';

export default {
  name: 'DownloadFile',
  props: {
    param: Object,
    location: String,
  },
  data() {
    return {
      loading: false,
      color: BASE_BLACK_COLOR,
      baseColor: BASE_COLOR,
    };
  },
  methods: {
    ...mapActions({
      fetchFile: 'specialSubscribers/fetchFile',
    }),
    getCSV() {
      this.loading = true;
      const url =
        this.location === 'festgeld'
          ? '/api/nibc/festgeld/csv'
          : '/api/nibc/tagesgeld/csv';
      this.fetchFile(url).then((res) => {
        this.loading = false;
        const extension = EXTENSIONS[res.headers['content-type']];
        const url = window.URL.createObjectURL(res.data);
        let a = document.createElement('a');
        a.href = url;
        a.download = this.location + extension;
        document.body.appendChild(a);
        a.click();
        setTimeout(function () {
          document.body.removeChild(a);
        }, 0);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.download-file {
  margin-top: 20px;
}
.tooltip {
  transform: translateY(-70px);
}
.tooltip-container {
  position: relative;

  & > a {
    height: 36px;
    min-width: 40px !important;
    background-color: $text;
  }
}
.text-tooltip {
  white-space: pre;
}
</style>
