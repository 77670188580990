<template>
  <v-col lg="3" class="picker">
    <div class="data-picker">
      <v-menu
        color="#1194db"
        ref="menu"
        :close-on-content-click="false"
        v-model="menu"
        transition="scale-transition"
        offset-y
        left
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <div class="d-flex">
            <v-icon
              v-on="on"
              class="mdiCalendar"
              :class="{
                'active-calendar': attrs['aria-expanded'] === 'true',
              }"
              >{{ 'mdi-calendar' }}
            </v-icon>
          </div>
        </template>
        <v-date-picker
          locale="de-DE"
          :show-current="max"
          :allowed-dates="allowedDates"
          :color="color"
          v-model="date"
          reactive
          @input="saveDate"
          :min="min"
          :max="max"
        ></v-date-picker>
      </v-menu>
      <p v-if="date !== null" class="date">
        {{ $date(date).format('DD.MM.YYYY') }}
      </p>
      <p v-else class="date">Datum</p>
    </div>
  </v-col>
</template>

<script>
import { BASE_BLACK_COLOR } from '../../../../../src/utils/constants';

export default {
  name: 'SpezialabonnentenDatePicker',
  props: {
    dates: Array,
    originalArr: Array,
    storeNamespace: {
      type: String,
      default: 'specialSubscribers',
    },
    storePath: String,
  },
  computed: {
    getDate() {
      return this.$store.getters[this.storeNamespace + '/getFieldValue'](
        this.storePath
      );
    },
  },
  data() {
    return {
      color: BASE_BLACK_COLOR,
      date: null,
      menu: false,
      min: '',
      max: '',
    };
  },
  watch: {
    dates(val) {
      this.min = this.$date(new Date(Math.min(...val))).format('YYYY-MM-DD');
      this.max = this.$date(new Date(Math.max(...val))).format('YYYY-MM-DD');
    },
    getDate(val) {
      if (val !== null) {
        this.date = this.$date(val).format('YYYY-MM-DD');
      } else {
        this.date = val;
      }
    },
  },
  mounted() {
    if (this.getDate !== null) {
      this.date = this.$date(this.getDate).format('YYYY-MM-DD');
      this.min = this.$date(new Date(Math.min(...this.dates))).format(
        'YYYY-MM-DD'
      );
      this.max = this.$date(new Date(Math.max(...this.dates))).format(
        'YYYY-MM-DD'
      );
    }
  },
  methods: {
    allowedDates(val) {
      return this.dates.find((item) => {
        return this.$date(new Date(item)).format('YYYY-MM-DD') === val;
      });
    },
    saveDate(date) {
      this.$refs.menu.save(date);
      const item = this.originalArr.find((item) => {
        return this.$date(new Date(item.datum)).format('YYYY-MM-DD') === date;
      });
      this.$store.commit(this.storeNamespace + '/setFieldValue', {
        path: this.storePath,
        value: date,
      });
      this.$emit('changed', item);
    },
  },
};
</script>
<style lang="scss">
.picker {
  position: relative;
  padding-top: 10px !important;
  padding-left: 0 !important;
}
.active-calendar {
  color: $text !important;
}
</style>
